<template>
	<div class="container">
		<div class="w1200">
			<div class="nav_top">当前位置：<router-link :to="{path:'/'}">首页</router-link> > <router-link :to="{path:'/reservation'}">预约服务</router-link> > {{getIndex(type)}}</div>
			<img src="@/assets/images/step1.png"/>
			<div class="content" v-loading="loading">
				<div class="content_date">
					<el-row :gutter="20" >
						<el-col :span="4" v-for='(item,index) in weekList' :key="index">{{item}}</el-col>
					</el-row>
					<el-row :gutter="20" >
						<el-col :span="4" v-for="(item,index) in dateList" :key="index"  >
							<div class="date"  :class="{'checked' : dateIndex == index}" @click="handleDate(index)">
								<div :class="{'default' : item.state == 1}">
									<p>{{item.date}}</p>
									<p :class="{'yes' : item.state == 0}">{{item.state == 0 ? '可预约' : '不可预约'}}</p>
								</div>
								
							</div>
						</el-col>
					</el-row>

				</div>
				
				<div class="time" v-if="bookingDate">
					<p class="flex_a"><img src="@/assets/images/dian.png"/>入馆时段</p>
					<el-row :gutter="20">
						<el-col :span="4" v-for="(item,index) in timeList" :key="index" :class="{'checked' : timeIndex == index}" >
							<div class="time_s" @click="handleTimeClick(index)">{{item.startPeriod}}-{{item.endPeriod}}</div>
							<span v-if="bookingDate && bookingPeriodId">该时段已预约<label>{{num}}</label>人</span>
						</el-col>
					</el-row>
				</div>
				<div class="btn">
					<el-button type="primary" @click="handleSubmit">确认选择</el-button>
				</div>
			</div>
			
			
		</div>

	</div>
</template>

<script>
	import urlobj from '@/api/baseurl.js'
	export default {
		data() {
			return {
				weekList: [],
				dateList: [],
				dateIndex: -1,
				timeIndex: -1,
				timeList: [],
				type: null,
				loading: false,
				num: 0,
				bookingDate: '',
				bookingPeriodId: '',
				bookingTime:'',
				
			}
		},
		created() {
			
		},
		mounted() {
			this.type = this.$route.query.type
			this.getDateList()
			// this.getTimeList()
		},
		methods: {
			getDateList() {
				this.loading = true
				this.$http({
					url: `/bookingRecordApi/queryBookingDateList`,
					method: "POST",
				}).then((res) => {
					if(res.state == 10200){
						this.loading = false
						this.dateList = res.data.bookDateDtoList
						this.weekList = res.data.week
					}
					
				});
			},
			getTimeList() {
				let formData = new FormData()
					formData.append('bookingDate', this.bookingDate)
				this.$http({
					url: `/bookingRecordApi/queryBookingPeriodList`,
					method: "POST",
					data: formData
				}).then((res) => {
					if(res.state == 10200){
						this.timeList = res.data
					}
					
				});
			},
			handleDate(index) {
				if(this.dateList[index].state == 0){
					this.timeIndex = null
					this.bookingPeriodId = ''
					this.num = ''
					this.dateIndex = index
					this.bookingDate = this.dateList[index].comDate
					this.getTimeList()
				}
				
			},
			getIndex(index) {
				if(index == 0) return '个人预约'
				if(index == 1) return '团队预约'
				if(index == 2) return '政务接待'
			},
			handleTimeClick(index) {
				this.timeIndex = index
				this.bookingPeriodId = this.timeList[index].id
				this.bookingTime = this.timeList[index].startPeriod + '-' + this.timeList[index].endPeriod
				this.getNum()
			},
			getNum() {
				let formData = new FormData()
					formData.append('bookingDate', this.bookingDate)
					formData.append('bookingPeriodId', this.bookingPeriodId)
				this.$http({
					url: `/bookingRecordApi/queryBookingPersonTime`,
					method: "POST",
					data: formData
				}).then((res) => {
					if(res.state == 10200){
						this.num = res.data
					}
					
				});
			},
			handleSubmit() {
				if(!this.bookingDate){
					this.$message.error('请选择日期')
					return
				}
				if(!this.bookingPeriodId){
					this.$message.error('请选择时段')
					return
				}
				this.$router.push({ path: "/Reservation/step_two",query:{type:this.type,bookingDate:this.bookingDate,bookingPeriodId:this.bookingPeriodId,bookingTime:this.bookingTime}});
			}
			
			
		}
	}
</script>

<style lang="scss" scoped>
.container{
	.w1200{
		min-height: calc(100vh - 354px);
		background: #FFFFFF;
		padding-bottom: 60px;
		>img {
			margin: 0 auto;
			margin-bottom: 20px;
			margin-top: 50px;
		}
		.content{
			min-height: 453px;
			width: 950px;
			background: url('../../assets/images/kuang.png') no-repeat;
			background-size: 100% 100%;
			margin: 0 auto;
			margin-top: 30px;
			padding: 40px 60px;
			font-size: 16px;
			color: #595E63;
			.content_date{
				.el-col-4{
					width: 14.2%;
				}
				.el-col{
					text-align: center;
					.date{
						border: 1px solid #F1F1F1;
						background: #FAFAFA;
						border-radius: 30px;
						padding: 8px 0;
						margin-top: 20px;
						cursor: pointer;
						height: 59px;
						display: flex;
						align-items: center;
						justify-content: center;
						p{
							&:nth-of-type(1){
								color: #595E63;
							}
							&:nth-of-type(2){
								color: #A5343C;
								font-size: 14px;
							}
						}
						.yes{
							color: #29C0BB !important;
						}
					}
					.checked{
						background: #A5343C;
						p{
							color: #FFF !important;
						}
						.yes{
							color: #FFF !important;
						}
						
					}
					.default{
						p{
							color: #ABABAB !important;
							&:nth-of-type(2){
								color: #A5343C !important;
							}
						}
						cursor: default;
					}
				}
			}
			.time{
				margin-top: 30px;
				color: #595E63;
				font-size: 16px;
				img{
					margin-right: 10px;
				}
				.el-row{
					.el-col{
						margin-top: 20px;
						cursor: pointer;
						position: relative;
						span{
							position: absolute;
							font-size: 12px;
							color: #ABABAB;
							left: 20px;
							bottom: -18px;
							display: none;
							label{
								color: #7D2626;
							}
						}
						.time_s{
							border: 1px solid #F1F1F1;
							border-radius: 30px;
							padding: 10px 0;
							text-align: center;
						}
						
					}
					.checked{
						.time_s{
							background: #A5343C;
							color: #FFFFFF;
						}
						span{
							display: inline-block;
						}

					}
				}
			}
			.btn{
				text-align: center;
				margin-top: 40px;
			}
		}
	
	}
}
	
</style>
